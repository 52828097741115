/* components/SearchLease.css */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Linear+A&family=Roboto+Condensed:wght@300;400&display=swap');

body {
  background-color: #0a0037;
  color: gray;
  font-family: 'Noto Sans Linear A', sans-serif;
}



button {
  background-color: #76f0c5;
  color: 0a0037;
  border-radius: 10px; /* Adjust the border-radius for rounded corners */
  padding: 10px 20px; 
  font-weight: bold;
}

h1, h2 {
  color: white;
  font-weight: bold;
}

/* Login */

.login-container {
  position: relative;
  width: 300px; /* Adjust as needed */
  margin: 0 auto;
}

.filter-log-container {
  position: relative;
  width: 125px; /* Adjust as needed */

}

input {
  color-scheme: dark;
}

/* Search */

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%; /* Adjust as needed */
  margin: 0 auto;
  position: relative; /* Set the position to relative */
}

.search-div,
.selected-lease {
  width: 50%; /* Set both divs to have 50% width */
  box-sizing: border-box; /* Include padding and border in the width */
}

input {
  font-family: 'Noto Sans Linear A', sans-serif;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  background-color: #0a0037;
  color: gray;
  margin-left: 0; /* Adjust as needed */
}

.searchSubmit {
  margin-left: 0; /* Adjust as needed */
}




label {
  display: block;
  color: #76f0c5; /* Green color for labels */
}

.options-dropdown {
  list-style: none;
  padding: 10px; /* Add padding for a cleaner look */
  margin: 0;
  margin: 5px 0 0 0; /* Adjust the top margin to reduce the gap */
  top: 100%; /* Position it below the input box */
  left: 0;
  max-width: 100%;
  max-height: 200px; /* Set a fixed height */
  overflow-y: auto; /* Add vertical scroll if content exceeds the fixed height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  color: black;
  background-color: #fff; /* Set a background color */
  z-index: 1;
}

.options-dropdown li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.options-dropdown li:last-child {
  border-bottom: none;
}

.options-dropdown li:hover {
  background-color: #f5f5f5;
}


/* Table */

.selected-lease {
  text-align: center; /* Center the content */
  height: 350px; /* Set a fixed height */
  overflow: auto; /* Add scroll if content exceeds the fixed height */
  margin-left: 20px; /* Adjust as needed */
}

table {
  width: 100%; /* Adjust as needed */
  margin-left: 0; /* Adjust as needed */
}

th {
  color: white;
}

th,
td {
  text-align: left;
  padding: 8px;
  border: 1px solid #dee2e6;
}

/* components/Header.css */

.app-header {
  background-color: #0a0037; /* Set to your background color */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  margin-right: 20px; /* Adjust as needed */
}

.logo {
  width: 112px; /* Set to your logo dimensions */
  height: auto;
}

.heading {
  font-weight: bold;
  color: white;
  font-size: 24px;
}

.loadingGif {
  width: 50px; /* Adjust the width to your preference */
  height: 50px; /* Adjust the height to your preference */
}